.stepsContent {
  width: max-content;
  margin: auto;
  margin-top: 48px;
  max-width: 100%;
  min-width: 520px;
}

.stepsAction {
  margin-top: 24px;
}
