.container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.logo {
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}

.login {
  align-self: center;
}

.loginForm {
  min-width: 300px;
}

.loginFormForgot {
  float: right;
}

.login-form {
  max-width: 300px;
}

.loginFormButton {
  width: 100%;
}

.login-form-forgot {
}

.ant-col-rtl .login-form-forgot {
  float: left;
}

.login-form-button {
  width: 100%;
}
