.logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }
  
  .site-layout .site-layout-background {
    background: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  [data-theme="dark"] .site-layout .site-layout-background {
    background: #141414;
  }
  